import { inject, Injectable } from '@angular/core';
import { HttpGeneralService } from '../../core/services/http-general/http-general.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotFoundService {
  private http = inject(HttpGeneralService);
  private translateService = inject(TranslateService);

  emitTelegramBot404Error(slug: string): Observable<any> {
    const locale = this.translateService.currentLang;

    return this.http.post('custom/notification/not-found', { slug, locale });
  }
}
