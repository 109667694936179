import { Component, inject, PLATFORM_ID } from '@angular/core';
import { MatIconService } from './core/utils/mat-icon/mat-icon.service';
import { filter, from, map, Observable, of, switchMap, take, tap, zip } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { ScanTablesService } from './shared/api/scan/scan-tables.service';
import { ShortLinkService } from './shared/api/short-link/short-link.service';
import { ScanService } from './modules/main/scan/services/scan.service';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { NotFoundService } from './shared/services/not-found.service';

@Component({
  standalone: false,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  private document = inject(DOCUMENT);
  private isServer = isPlatformServer(inject(PLATFORM_ID));
  private router = inject(Router);
  private notFoundService = inject(NotFoundService);

  offlineLoading: boolean = false;

  constructor(
    private matIconService: MatIconService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private db: NgxIndexedDBService,
    private scanTablesService: ScanTablesService,
    private shortLinkService: ShortLinkService,
    private scanService: ScanService,
  ) {
    this.matIconService.registerCustomIcons();

    // const body: any = {
    //   slug: 'test',
    //   categories: [{ qr_categories_id: 7 }, { qr_categories_id: 8 }],
    //   translations: [
    //     {
    //       languages_code: 'en',
    //       title: 'title design',
    //       description: 'test description',
    //       qr_design: { qr_designs_id: '5d7e1021-156f-4db2-ad08-da0674bd4a3c' },
    //     },
    //   ],
    // };

    // inject(HttpGeneralService)
    //   .post('items/qr_templates', body)
    //   .subscribe((res) => {
    //   });

    // inject(SwPush)
    //   .requestSubscription({
    //     serverPublicKey:
    //       'BNZS170vunFuhLrmLTQinp2ZiX3Mg2J_kNdg2kaj8os6lz8KxmFF1WbaDt3qeOVeszBKDFGRkDbAKVRlosnKD-A',
    //   })
    //   .then((res: any) => {
    //     console.log(JSON.stringify(res));
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    // this.loadTablesFromDB();

    // titleService.setTitle(
    //   translateService.currentLang === 'uk'
    //     ? 'Qroot.app - Безкоштовний генератор QR кодів, веб та друк форм'
    //     : 'Qroot.app - Free generator of QR codes, web and print forms',
    // );

    // if (translateService.currentLang === 'uk') {
    //   metaService.addTag({
    //     name: 'description',
    //     content:
    //       'Створи свої QR коди або веб форми. Слідкуй за статистикою та аналітикою. Налаштуй інвентаризацію та користуйся нашим сканером. Використовуй готові шаблони',
    //   });
    // } else {
    //   metaService.addTag({
    //     name: 'description',
    //     content:
    //       'Create your own QR codes or web forms. Keep track of statistics and analytics. Set up an inventory and use our scanner. Use ready-made templates',
    //   });
    // }

    this.document.documentElement.setAttribute('lang', translateService.currentLang);

    this.router.events
      .pipe(
        filter((event) => {
          return event instanceof NavigationEnd;
        }),
      )
      .subscribe((event: any) => {
        if (this.router.url !== event.url && this.router.url === '/404' && event.url) {
          this.notFoundService.emitTelegramBot404Error(event.url).pipe(take(1)).subscribe();
        }
      });
  }

  combineTranslateTransforms(transform: string): string {
    // Regex patterns for translate and translate3d functions
    const translatePattern = /(translate\([^\)]*\)|translate3d\([^\)]*\))/g;

    // Split the transform string into individual transform functions
    const matches = transform.match(translatePattern);
    let translateX = 0,
      translateY = 0,
      translateZ = 0;

    // Process all translate and translate3d functions and accumulate values
    if (matches) {
      matches.forEach((match) => {
        if (match.startsWith('translate3d')) {
          const values = match
            .slice(12, -1)
            .split(',')
            .map((v) => parseFloat(v.trim()));
          translateX += values[0] || 0;
          translateY += values[1] || 0;
          translateZ += values[2] || 0;
        } else if (match.startsWith('translate(')) {
          const values = match
            .slice(10, -1)
            .split(',')
            .map((v) => parseFloat(v.trim()));
          translateX += values[0] || 0;
          translateY += values[1] || 0;
        }
      });
    }

    // Generate the combined translate3d or translate function
    let combinedTranslate = '';
    if (translateZ !== 0) {
      combinedTranslate = `translate3d(${translateX}px, ${translateY}px, ${translateZ}px)`;
    } else {
      combinedTranslate = `translate(${translateX}px, ${translateY}px)`;
    }

    // Remove all translate-related transforms from the original string
    const otherTransforms = transform.replace(translatePattern, '').trim();

    // Combine the final transform string
    return otherTransforms ? `${combinedTranslate} ${otherTransforms}` : combinedTranslate;
  }

  updateStyles(styles: any): any {
    const data: any = {};

    Object.entries(styles).forEach(([key, value]: any) => {
      switch (key) {
        case 'width':
          if (!value.toString().includes('px')) {
            value += 'px';
          }
          break;
        case 'height':
          if (!value.toString().includes('px')) {
            value += 'px';
          }
          break;
        case 'fontSize':
          if (!value.toString().includes('px')) {
            value += 'px';
          }
          break;
        case 'fontWeight':
          if (value === 'Regular') {
            value = 400;
          }
          break;
        case 'transform':
          value = this.combineTranslateTransforms(value);
          break;
      }

      data[key] = value;
    });

    return data;
  }

  checkNetworkStatus(messages: any) {
    // merge(of(null), fromEvent(this.window, 'online'), fromEvent(this.window, 'offline'))
    //   .pipe(
    //     map(() => this.window.navigator.onLine),
    //     takeUntil(this.destroyed$),
    //   )
    //   .subscribe((status) => {
    //     if (!status) {
    //       this.isOfflineBefore = true;
    //       this.toastr.error(messages['No internet connection! You can work offline.'], '', {
    //         closeButton: true,
    //         extendedTimeOut: 0,
    //         timeOut: 0,
    //         tapToDismiss: false,
    //       });
    //     } else if (status && this.isOfflineBefore) {
    //       this.toastr.success(messages['Connection restored!']);
    //       this.loadTablesFromDB();
    //     }
    //   });
  }

  translateMessages(): void {
    // this.translateService
    //   .get(['No internet connection! You can work offline.', 'Connection restored!'])
    //   .pipe(take(1))
    //   .subscribe((res) => {
    //     this.checkNetworkStatus(res);
    //   });
  }

  // loadTablesFromDB(): void {
  //   if (this.isServer) {
  //     return;
  //   }
  //   let messages: any = [];
  //   this.translateService
  //     .get(['Your local data is loading', 'Your local data is updated!'])
  //     .pipe(take(1))
  //     .subscribe((res) => {
  //       messages = res;
  //     });
  //   this.db
  //     .getAll('list')
  //     .pipe(take(1))
  //     .subscribe((res: any) => {
  //       if (res.length && res[0]) {
  //         let req: any;
  //         if (res[0].isOffline) {
  //           delete res[0].isOffline;
  //           req = this.scanTablesService.createTable({ ...res[0], id: undefined });
  //         } else {
  //           delete res[0].isOffline;
  //           req = this.scanTablesService.updateTable(res[0].id, res[0]);
  //         }
  //
  //         req.pipe(take(1)).subscribe((data1: any) => {
  //           let data = JSON.parse(JSON.stringify(data1));
  //           zip(
  //             data.data.content.map((el: any) => {
  //               if (el.qr_url.includes('id":')) {
  //                 const settings = JSON.parse(el.qr_url);
  //                 return this.shortLinkService.createShortLink(settings).pipe(take(1));
  //               } else {
  //                 return of({
  //                   data: {
  //                     uuid: el.qr_url,
  //                   },
  //                 });
  //               }
  //             }),
  //           )
  //             .pipe(take(1))
  //             .subscribe((final: any) => {
  //               data.data.content.forEach((el: any, index: number) => {
  //                 el.qr_url = final[index].data.uuid;
  //               });
  //               this.toastr.info(messages['Your local data is loading']);
  //               this.offlineLoading = true;
  //               this.loadMedia()
  //                 .pipe(take(1))
  //                 .subscribe((media) => {
  //                   media.forEach((med: any) => {
  //                     if (data.data.content[med.index][med.type]) {
  //                       if (med.type !== 'video') {
  //                         data.data.content[med.index].photo.push(
  //                           med.data.formats.large?.url ||
  //                             med.data.formats.small?.url ||
  //                             med.data.formats.thumbnail?.url,
  //                         );
  //                       } else {
  //                         data.data.content[med.index].video.push(med.data.url);
  //                       }
  //                     } else {
  //                       if (med.type !== 'video') {
  //                         data.data.content[med.index].photo = [
  //                           med.data.formats.large?.url ||
  //                             med.data.formats.small?.url ||
  //                             med.data.formats.thumbnail?.url,
  //                         ];
  //                       } else {
  //                         data.data.content[med.index].video = [med.data.url];
  //                       }
  //                     }
  //                   });
  //                   this.scanTablesService
  //                     .updateTable(data.data.id, data.data)
  //                     .pipe(take(1))
  //                     .subscribe(() => {
  //                       this.db
  //                         .clear('list')
  //                         .pipe(take(1))
  //                         .subscribe(() => {
  //                           this.db
  //                             .clear('files')
  //                             .pipe(take(1))
  //                             .subscribe(() => {
  //                               this.offlineLoading = false;
  //                               this.toastr.success(messages['Your local data is updated!']);
  //                             });
  //                         });
  //                     });
  //                 });
  //             });
  //         });
  //       }
  //     });
  // }

  loadMedia(): Observable<any> {
    if (this.isServer) {
      return of();
    }
    return this.db.getAll('files').pipe(
      take(1),
      switchMap((res) => {
        let media: any = [];
        res.forEach((el: any) => {
          media.push(
            this.uploadFile(el.file, el.type === 'video').pipe(
              map((file) => ({ ...file, index: el.index, type: el.type })),
            ),
          );
        });
        return media.length ? zip(media) : of([]);
      }),
    );
  }

  uploadFile(file: any, isVideo: boolean): Observable<any> {
    return from(this.blobToBase64(file)).pipe(
      take(1),
      switchMap((base64: string) => {
        let fileObject = {
          fileInfo: JSON.stringify({
            caption: 'test',
            alternativeText: 'sdfs',
            name: 'sdfssa123',
          }),
          mime: '',
          file: '',
        };
        let h = base64.split(',');
        fileObject.mime = h[0].substring(0, h[0].indexOf('base64'));
        fileObject.file = h[1];
        if (isVideo) {
          return this.scanService.takeScanVideo(fileObject).pipe(take(1));
        } else {
          return this.scanService.takeScanPhoto(fileObject).pipe(take(1));
        }
      }) as any,
    );
  }

  blobToBase64(file: File) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(file);
    });
  }
}
